<template>
  <div class='login_shop'>
    <div class="blur"></div>
    <div class="form_body">
      <div class="back" @click="$router.replace('login')">
        <i class="el-icon-arrow-left"></i>
        <span>返回</span>
      </div>
      <h3 class="title">选择店铺</h3>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" :class="active == item.shopId ? 'is_active' : ''" @click="choiceShop(item)">
          <img :src="item.logoUrl || avatar" width="60" height="60">
          <div class="shopname">{{ item.shopName }}</div>
          <img v-if="active == item.shopId" src="../assets/img/login/choice2.png" width="26" height="26">
          <img v-else src="../assets/img/login/choice1.png" width="26" height="26">
        </div>
      </div>
      <!-- <div class="btn">
        <el-button type="primary" :loading="btnLoading" @click="submitForm">登录</el-button>
      </div> -->
    </div>

    <footers class="login_footers" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      avatar: require('../assets/img/avatar.png'),
      active: null,
      url: '',
      list: [],
      btnLoading: false,
    }
  },
  created () {
    this.list = this.$store.state.shopList
  },
  methods: {
    choiceShop (item) {
      this.active = item.shopId
      setTimeout(() => {
        if (this.active) {
          this.btnLoading = true
          this.$axios.post(`${this.$api.choiceShop}/${this.active}`).then((res) => {
            const { code, result } = res.data.result
            if (code == 100) {
              if (sessionStorage.getItem('loginExpiredShown')) sessionStorage.removeItem('loginExpiredShown');
              this.$store.commit('setToken', result.token)
              this.$store.commit('setUserInfo', result.loginUser)
              this.$store.commit('setMenuList', result.sysMenuVoList)
              this.$store.commit('setIsLoggedIn', true)
              this.$store.commit('setCurrentShopName', item.shopName)
              this.$store.commit('setShopList', result.shopInfoVoList)
              this.$message.success('登录成功');
              setTimeout(() => {
                if (result.sysMenuVoList.length > 0) {
                  this.$router.replace({ path: result.sysMenuVoList[0].menuPath })
                } else {
                  this.$router.replace('/404')
                }
              }, 200);
              this.btnLoading = false
            }
          }).catch(() => {
            this.btnLoading = false
          })
        } else {
          this.$message.error('请选择店铺')
        }
      }, 100);
    },
  },
}
</script>

<style scoped lang='scss'>
.login_shop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;


  .blur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../assets/img/login/login.png) no-repeat;
    background-size: cover;
    background-position: 100% 30%;
    filter: blur(4px);
  }

  .form_body {
    position: relative;
    min-width: 460px;
    padding: 30px;
    border-radius: 6px;
    background-color: #fff;
    box-sizing: border-box;

    .back {
      color: #68706E;
      font-size: 16px;
      cursor: pointer;

      span {
        margin-left: 10px;
      }
    }

    .title {
      color: #1a1a1a;
      font-size: 26px;
      text-align: center;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .list {
      height: 300px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
      }

      .item {
        display: flex;
        align-items: center;
        color: #68706E;
        font-size: 18px;
        padding: 15px 20px;
        margin-bottom: 16px;
        border-radius: 10px;
        border: 1px solid #F2F2F2;
        background-color: #F2F2F2;
        cursor: pointer;

        .shopname {
          width: 250px;
          margin-left: 30px;
        }

        img {
          border-radius: 50%;
        }
      }

      .is_active {
        color: #069BBC;
        border: 1px solid #069BBC;
        background-color: #E1F3F7;
      }
    }

    .btn {
      padding-top: 20px;

      .el-button {
        width: 100%;
        height: 50px;
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }
}
</style>
